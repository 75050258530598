
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { NavBar, Row, Col, Icon, Image, List, Cell } from "vant";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [List.name]: List,
    [Cell.name]: Cell,
  },
})
export default class PrintRecords extends Mixins(Mixin) {
  public activityId: string | (string | null)[] = "";
  public RecordList: Array<member.GetMyPrintingRecordResponse> = [];
  created(): void {
    window.document.title = "印花记录";
    if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge("isShowShare", "0");
    }
    this.activityId = this.$route.query.activityId;
    this.getRecords();
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  getRecords(): void {
    this.$api.memberApi.printing.myPrintingRecord(
      {
        activityId: String(this.activityId),
      },
      ({ data }) => {
        this.RecordList = data;
      }
    );
  }
}
